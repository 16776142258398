<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img
          v-if="user.image"
          :src="user.image"
        ></v-img>
        <v-img
          v-else
          src="@/assets/images/logos/literasia.png"
        ></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-if="user.role ==='school'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.npsn"
              label="NPSN"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.nik"
              label="NIK"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.nip"
              label="NIP"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='school'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-if="user.role ==='teacher' || user.role ==='admin'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.school"
              label="Sekolah"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='school'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.semester"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Semester"
              :items="semesters"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='school'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.level"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Jenjang"
              :items="levels"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='school'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.school_years"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Tahun Ajaran"
              :items="school_years"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.gender"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Jenis Kelamin"
              :items="genders"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.religion"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Agama"
              :items="religions"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.marital_status"
              dense
              outlined
              item-text="name"
              item-value="name"
              label="Status Menikah"
              :items="marital_status"
            ></v-select>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.date_of_work"
              dense
              outlined
              label="Tahun Kerja"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.status_work"
              dense
              outlined
              label="Status Kerja"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.first_degree"
              dense
              outlined
              label="Gelar Pertama"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.last_degree"
              dense
              outlined
              label="Gelar Terakhir"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.place_of_birth"
              dense
              outlined
              label="Tempat Lahir"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-menu
              v-model="date"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user.date_of_birth"
                  label="Tanggal Lahir"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="user.date_of_birth"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="date = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="user.province"
              dense
              outlined
              item-text="name"
              item-value="province.name"
              return-object
              :items="provinces"
              label="Provinsi"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="user.regency"
              item-text="name"
              item-value="regency.name"
              return-object
              dense
              :items="regencies"
              outlined
              label="Kabupaten"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="user.district"
              item-text="name"
              item-value="district.name"
              return-object
              dense
              :items="districts"
              outlined
              label="Kecamatan"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="user.village"
              item-text="name"
              item-value="village.name"
              return-object
              dense
              outlined
              label="Kelurahan/Desa"
            ></v-autocomplete>
          </v-col>

          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="user.rt"
              dense
              outlined
              label="RT"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="user.rw"
              dense
              outlined
              label="Rw"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="user.zip_code"
              dense
              outlined
              label="Kode Pos"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.phone"
              dense
              outlined
              label="Nomor Telepon"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="user.role ==='teacher'"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.phone_house"
              dense
              outlined
              label="Telepon Rumah"
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <v-col cols="12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Your email is not confirmed. Please check your inbox.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
            >
              Save changes
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="returnBack"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  name: 'AccountSettinggsAccount',
  data() {
    return {
      user: {
        name: '',
        email: '',
        role: '',
        status: '',
        nik: '',
        nip: '',
        place_of_birth: '',
        date_of_birth: '',
        date_of_work: '',
        first_degree: '',
        last_degree: '',
        gender: '',
        image: null,
        religion: '',
        marital_status: '',
        is_active: '',
        is_moderator: '',
        province: '',
        regency: '',
        district: '',
        village: '',
        status_work: '',
        phone: '',
        phone_house: '',
        school_year: '',
        level: '',
      },
      date: false,
      details: {
        name: '',
      },
      icons: { mdiAlertOutline, mdiCloudUploadOutline },
      status: [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Pending' },
      ],
      semesters: [
        { id: 1, name: 'Ganjil' },
        { id: 2, name: 'Genap' },
      ],
      levels: [
        { id: 1, name: 'SD' },
        { id: 2, name: 'SMP' },
        { id: 3, name: 'SMA' },
        { id: 4, name: 'SMK' },
        { id: 5, name: 'MA/MTS/MI' },
      ],
      school_years: [
        { id: 1, name: '2018/2019' },
        { id: 2, name: '2019/2020' },
        { id: 3, name: '2020/2021' },
        { id: 4, name: '2021/2022' },
        { id: 5, name: '2022/2023' },
      ],
      marital_status: [{ name: 'Sudah Menikah' }, { name: 'Belum Menikah' }],
      religions: [
        { name: 'Islam' },
        { name: 'Protestan' },
        { name: 'Katholik' },
        { name: 'Budha' },
        { name: 'Hindu' },
        { name: 'Konghuchu' },
      ],
      genders: ['Laki-Laki', 'Perempuan'],
      provinces: [],
      regencies: [],
      districts: [],
      villages: [],
      roles: [],
      role: '',
    }
  },
  async mounted() {
    await this.getUser()
    await this.checkRole()
    await this.getProvince()
    await this.getRegency()
    await this.getDistrict()
    await this.getVillage()
  },
  methods: {
    getUser() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      this.user.name = user.name
      this.user.email = user.email
      this.user.role = user.role
      this.user.status = this.status.filter(item => item.name.toLowerCase() === user.status.toLowerCase())[0].name
      this.user.npsn = user.details[0].npsn
      this.user.username = user.details[0].username
      this.user.semester = user.details[0].semester
      this.user.level = user.details[0].jenjang
      this.user.year = user.details[0].tahun_ajaran
      this.user.image = user.details[0].image
      this.user.nik = user.details[0].nik
      this.user.nip = user.details[0].nip
      this.user.school = user.details[0].school.name
      this.user.gender = user.details[0].gender
      this.user.religion = user.details[0].religion
      this.user.marital_status = user.details[0].marital_status
      this.user.date_of_work = user.details[0].date_of_work
      this.user.status_work = user.details[0].status_work
      this.user.first_degree = user.details[0].first_degree
      this.user.last_degree = user.details[0].last_degree
      this.user.place_of_birth = user.details[0].place_of_birth
      this.user.date_of_birth = user.details[0].date_of_birth
      this.user.province = user.details[0].place.province.name
      this.user.regency = user.details[0].place.regencies.name
      this.user.district = user.details[0].place.district.name
      this.user.village = user.details[0].place.villages.name
      this.user.rt = user.details[0].place.rt
      this.user.rw = user.details[0].place.rw
      this.user.zip_code = user.details[0].place.zip_code
      this.user.phone = user.details[0].phone
      this.user.phone_house = user.details[0].phone_house
      this.user.school_year = user.details[0].school_year
      this.user.level = user.details[0].level
    },
    checkRole() {
      this.roles = JSON.parse(localStorage.getItem('user')).user.role
      if (this.roles === 'admin') {
        this.user.role = this.roles
      } else if (this.roles === 'school') {
        this.user.role = this.roles
      } else if (this.roles === 'teacher') {
        this.user.role = this.roles
      }
    },
    async getProvince(params = {}) {
      await this.$services.ApiServices.list('province', { ...params, per_page: 'all' }).then(({ data }) => {
        this.provinces = data.data
      })
    },
    async getRegency(params = {}) {
      await this.$services.ApiServices.list('regency', { ...params, per_page: 'all' }).then(({ data }) => {
        this.regencies = data.data
      })
    },
    async getDistrict(params = {}) {
      await this.$services.ApiServices.list('district', { ...params, per_page: 'all' }).then(({ data }) => {
        this.districts = data.data
      })
    },
    async getVillage(params = {}) {
      await this.$services.ApiServices.list('village', { ...params, per_page: 'all' }).then(({ data }) => {
        this.villages = data.data
      })
    },
    returnBack() {
      this.$router.back()
    },
  },
  // props: {
  //   accountData: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  // setup(props) {
  //   const status = ['Active', 'Inactive', 'Pending', 'Closed']

  //   const user = ref(JSON.parse(JSON.stringify(props.accountData)))

  //   const resetForm = () => {
  //     user.value = JSON.parse(JSON.stringify(props.accountData))
  //   }

  //   return {
  //     status,
  //     user,
  //     resetForm,
  //     icons: {
  //       mdiAlertOutline,
  //       mdiCloudUploadOutline,
  //     },
  //   }
  // },
}
</script>
