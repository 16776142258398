<template>
  <v-card>
    <div class="theme-color">
      <v-card-title>
        <v-icon
          size="26"
          class="text--primary me-3"
        >
          {{ icons.mdiChartTimelineVariant }}
        </v-icon>
        <span>Tema Warna</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-menu
            nudge-right="50"
            nudge-top="8"
            class="theme-color"
          >
            <!-- Activator -->
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class=""
                style="width: 40px"
                v-on="on"
              >
                <div
                  :class="classSelectedThemeColor"
                >
                </div>
                <span></span>
              </div>
            </template>

            <v-list>
              <v-list-item-group
                class="d-flex"
              >
                <v-list-item
                  v-for="color in colorThemes"
                  :key="color.background"
                  class=""
                  style="padding: 0 8px"
                >
                  <div class="d-flex">
                    <div

                      :class="'btn-theme-child '+color.class"
                    >
                    </div>
                  </div>
                <!-- <v-list-item-title class="ml-2">{{ color.title }}</v-list-item-title> -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <!-- <div class="d-flex" >
            <div class="mr-4" v-for="color in colorThemes" :key="color.title">
              <div class="mb-3">{{ color.title }}</div>
              <div :class="color.class"></div>
            </div>
        </div> -->
      </v-card-text>
    </div>
    <div class="theme-languange">
      <v-card-title>
        <v-icon
          size="26"
          class="text--primary me-3"
        >
          {{ icons.mdiChartTimelineVariant }}
        </v-icon>
        <span>Bahasa</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-menu
            nudge-right="50"
            nudge-top="8"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="theme-flag"
                style="width: 40px"
                v-on="on"
              >
                <v-img
                  :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                  :alt="$i18n.locale"
                ></v-img>
              </div>
            </template>
            <v-list>
              <v-list-item-group
                :value="$i18n.locale"
                class="d-flex"
                @change="updateActiveLocale"
              >
                <v-list-item
                  v-for="locale in locales"
                  :key="locale.locale"
                  :value="locale.locale"
                  style="padding: 0 8px"
                >
                  <v-img
                    :src="locale.img"
                    height="40px"
                    width="40px"
                    :alt="locale.locale"
                  ></v-img>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import { loadLanguageAsync } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const colorThemes = [
      { title: 'Default', background: '#EA008C', class: 'colorThemeDefault' },
      { title: 'Bluesky', background: '#005EDD', class: 'colorThemeBluesky' },
    ]
    const locales = [
      { title: 'English', img: require('@/assets/images/flags/en.png'), locale: 'en' },
      { title: 'Indonesia', img: require('@/assets/images/flags/id.png'), locale: 'id' },
    ]
    const classSelectedThemeColor = 'bg-theme-pink btn-theme-parent'
    const classSelectedThemeLanguange = ''

    const updateActiveLocale = locale => {
      loadLanguageAsync(locale)
    }

    return {
      locales,
      updateActiveLocale,
      colorThemes,
      classSelectedThemeColor,
      classSelectedThemeLanguange,
      icons: {
        mdiChartTimelineVariant,
        mdiEmailOutline,
      },
    }
  },
}
</script>

<style>
</style>
